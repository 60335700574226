.font-voucher-print {
  font-family: "FakeReceipt" !important;
}

.product-detail-desc > ul,
.product-detail-desc > ol {
  list-style-position: inside;
}

.zoom-image img {
  min-width: 320px;
  max-height: 400px;
  object-fit: contain;
}

html.swal2-shown,
body.swal2-shown {
  /* overflow-y: hidden !important; */
  height: auto !important;
}

.sweetAlertTitleStyle {
  padding: 0 2rem !important;
}

.sweetAlertIconStyle {
  border: 0 !important;
  height: 40px !important;
  width: 40px !important;
}

.custom-min-w-3rem {
  min-width: 3.5rem;
}

.custom-scrollbar-width::-webkit-scrollbar {
  width: 5px;
}

.bg-btn-pay-again {
  /* @apply text-white bg-gray-700; */
  color: #fff;
  background: #aaa;
}
.zoom-image img {
  margin-left: auto;
  margin-right: auto;
}
